import axios from "axios";
import useSWR from "swr";

type Options = Partial<Pick<Area, "from_photo" | "from_upload" | "from_ai">>;

async function getAreas(params: Options): Promise<PhotoArea[]> {
  const { data } = await axios.get("/getCoordinates/", {
    params,
  });
  if (data.error) {
    throw new Error(data.message);
  }
  return data;
}

const useAreas = (options?: Options) =>
  useSWR(["areas", ...(options ? [options] : [])], ([_k, opts]) =>
    getAreas(opts)
  );

export default useAreas;
