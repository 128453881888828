import { cn } from "@/utils/cn";
import { DATE_KEYS } from "../../const/general";

export default function AttributeField({
  name,
  value,
  cols = 1,
  width = undefined,
  className = "",
}) {
  return (
    <td colSpan={cols} style={{ width }}>
      <div className="flex flex-col gap-1 text-[var(--text-color)]">
        <span className="opacity-80 text-sm">{name}</span>
        <span
          className={cn(
            "text-base break-words text-ellipsis line-clamp-6",
            className
          )}
        >
          {DATE_KEYS.includes(name)
            ? new Intl.DateTimeFormat("en-US", {
                dateStyle: "short",
                timeStyle: "short",
                timeZone: "UTC",
              }).format(new Date(value).getTime()) + " UTC"
            : value
            ? String(value)
            : "None"}
        </span>
      </div>
    </td>
  );
}
