import Map from "../components/map_with_search";
import Ticker from "@/components/ui/ticker";

export default function AddLocation() {
  return (
    <>
      <Ticker />
      <section className="h-full transition-all flex flex-col w-full pt-[54px]">
        <Map />
      </section>
    </>
  );
}
