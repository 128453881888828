import { ICON_TYPES, SEARCH_OPTIONS } from "@/const/search";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../ui/select";

type Props = {
  value?: string;
  onChange: (value: string) => void;
  className?: string;
};

const INVESTIGATION_MODULES = [
  "flickr",
  "twitter",
  "instagram",
  "youtube",
  "sports_tracker",
];

export default function ModuleSelect({ value, onChange, className }: Props) {
  return (
    <Select value={value} onValueChange={onChange}>
      <SelectTrigger className={className}>
        <SelectValue placeholder="Select module" />
      </SelectTrigger>
      <SelectContent>
        {SEARCH_OPTIONS.find((item) => item.title === "Social Media")!
          .options.filter((opt) => INVESTIGATION_MODULES.includes(opt.value))
          .map((opt) => (
            <SelectItem value={opt.value} key={opt.value}>
              <img
                className="mr-2 w-5 h-5 inline-block"
                src={ICON_TYPES[opt.value as keyof typeof ICON_TYPES]}
                alt=""
              />
              {opt.label}
            </SelectItem>
          ))}
      </SelectContent>
    </Select>
  );
}
