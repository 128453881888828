import { useAuth0 } from "@auth0/auth0-react";
import { useCallback, useMemo, useState } from "react";
import { geolocateUpload } from "../../utils/upload/lib";
import { useDropzone } from "react-dropzone";
import toast from "react-hot-toast";
import Toast from "../../components/Toast";
import { deleteCoordinates } from "@/utils/search/lib";
import useAreas from "@/lib/database/areas";

const baseClassName =
  "flex-1 flex flex-col items-center gap-8 justify-center p-5 border-2 rounded-lg border-[var(--text-color)] border-dashed text-[var(--text-color)] transition-colors";

const acceptedClassName =
  "border-[var(--pink-color)] text-[var(--pink-color)] dark:border-[var(--blue-color)] dark:text-[var(--blue-color)]";

const rejectedClassName = "border-red-500 text-red-500";

export default function usePhotoGeolocation({
  from_photo,
  from_upload,
  from_ai,
}: Partial<Pick<Area, "from_photo" | "from_upload" | "from_ai">>) {
  const [deleteItem, setDeleteItem] = useState<Pick<
    Area,
    "id" | "title"
  > | null>(null);
  const { mutate } = useAreas({ from_ai, from_photo, from_upload });
  const { getAccessTokenSilently } = useAuth0();
  const [isLoading, setIsLoading] = useState({
    removal: false,
    geolocation: false,
  });

  const handleGeolocation = useCallback(
    async (acceptedFiles: File[]) => {
      setIsLoading((prev) => ({ ...prev, geolocation: true }));
      const token = await getAccessTokenSilently();
      acceptedFiles.forEach((file) => {
        const reader = new FileReader();

        reader.onabort = () => console.log("file reading was aborted");
        reader.onerror = () => console.log("file reading has failed");
        reader.onload = async () => {
          // Do whatever you want with the file contents
          const binaryStr = reader.result;
          const filename = file.name;
          const { error } = await geolocateUpload(token, binaryStr, filename, {
            from_upload,
          });
          setIsLoading((prev) => ({ ...prev, geolocation: false }));
          if (error) {
            toast.custom((t) => (
              <Toast
                {...t}
                isError
                title="Something went wrong!"
                subtitle={error}
              />
            ));
          } else {
            mutate();
          }
        };
        reader.readAsDataURL(file);
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getAccessTokenSilently, from_photo, from_upload, from_ai]
  );

  const { isDragAccept, isDragReject, getRootProps, getInputProps } =
    useDropzone({
      onDrop: handleGeolocation,
      accept: {
        ...(from_upload
          ? {
              "text/csv": [".csv"],
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
                [".csv"],
              "application/vnd.ms-excel": [".csv"],
            }
          : {}),
        ...(from_photo
          ? {
              "image/*": [".png", ".jpeg", ".jpg"],
            }
          : {}),
      },
    });

  const className: string = useMemo(
    () =>
      `${baseClassName} ${isDragAccept ? acceptedClassName : ""} ${
        isDragReject ? rejectedClassName : ""
      }`,
    [isDragAccept, isDragReject]
  );

  async function handleDelete() {
    if (!deleteItem) return;
    setIsLoading((prev) => ({ ...prev, removal: true }));
    try {
      const token = await getAccessTokenSilently();
      const status = await deleteCoordinates(token, deleteItem.id);
      if (status && status < 300) {
        mutate();
        setDeleteItem(null);
      }
    } catch (e) {
      toast.custom((t) => (
        <Toast
          {...t}
          isError
          title="Something went wrong!"
          subtitle={(e as Error).message}
        />
      ));
    } finally {
      setIsLoading((prev) => ({ ...prev, removal: false }));
    }
  }

  const rootProps = getRootProps({ className });
  const inputProps = getInputProps();

  return {
    isLoading,
    rootProps,
    inputProps,
    deleteItem,
    setDeleteItem,
    handleDelete,
    handleGeolocation,
    toggleLoading: (loading: boolean) =>
      setIsLoading((prev) => ({ ...prev, geolocation: loading })),
  };
}
