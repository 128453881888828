import { faFile } from "@fortawesome/free-solid-svg-icons";
import usePhotoGeolocation from "../hooks/photo-geolocation/usePhotoGeolocation";
import AreaRef from "../components/photo-geolocation/area";
import Popup from "../components/Popup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { Button } from "@/components/ui/button";
import { Plus, SearchIcon } from "lucide-react";
import { useState } from "react";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { useAuth0 } from "@auth0/auth0-react";
import toast from "react-hot-toast";
import Toast from "../components/Toast";
import { getTaskInfo } from "../utils/single-item/lib";
import useAreas from "@/lib/database/areas";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import LoadingBar from "react-top-loading-bar";

type Props = { from_photo?: boolean; from_upload?: boolean; from_ai?: boolean };

export default function UploadPage({
  from_photo,
  from_upload,
  from_ai,
}: Props) {
  const {
    inputProps,
    rootProps,
    isLoading,
    deleteItem,
    setDeleteItem,
    handleDelete,
    handleGeolocation,
    toggleLoading,
  } = usePhotoGeolocation({ from_photo, from_upload, from_ai });
  const {
    data: results,
    mutate,
    isLoading: isPageLoading,
  } = useAreas({
    from_ai,
    from_photo,
    from_upload,
  });
  const [isProgressVisible, setIsProgressVisible] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [input, setInput] = useState("");
  const { getAccessTokenSilently } = useAuth0();

  //   const [results, setResults] = useState<PhotoArea[]>([]);
  const handleSearch = async (input: string) => {
    setIsProgressVisible(true);
    toggleLoading(true);
    const requestData = {
      text: input,
    };

    try {
      const token = await getAccessTokenSilently();
      const response = await fetch(
        `${process.env.REACT_APP_API_SERVER_URL}/api/aiSearch/`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          method: "POST",
          body: JSON.stringify(requestData),
        }
      );
      const data = await response.json();
      const taskId = data.task_id;
      if (!taskId.includes("-")) {
        // check if taskId is a uuid, if not it's an error regarding no credits
        toast.custom((t) => (
          <Toast {...t} isError title="Error" subtitle="No credits" />
        ));
        return;
      }
      const timeoutId: ReturnType<typeof setInterval> = setInterval(
        async () => {
          const {
            results: [{ state, result }],
          } = await getTaskInfo(taskId);
          if (state === "SUCCESS") {
            const error = result.error;
            if (error) {
              toast.custom((t) => (
                <Toast {...t} isError title="Error" subtitle={result.message} />
              ));
            } else {
              toast.custom((t) => (
                <Toast {...t} title="Success" subtitle="Results are ready" />
              ));
              mutate();
            }
          }
          if (state !== "PENDING" && state !== "PROGRESS") {
            clearInterval(timeoutId);
            setIsDialogOpen(false);
            toggleLoading(false);
          }
        },
        1000
      );
    } catch (err) {
      console.log(err);
      //       toast.dismiss(loadingId);
      toast.custom((t) => (
        <Toast {...t} isError title="Error" subtitle="Generic error" />
      ));
    }
  };

  if (
    isPageLoading ||
    (!from_ai &&
      (!Array.isArray(results) || results.length === 0) &&
      isLoading.geolocation)
  ) {
    return (
      <div className="w-full h-screen flex items-center justify-center">
        <l-waveform size={48} color="white" />
      </div>
    );
  }
  return (
    <section className="flex flex-col gap-4 w-full p-8 h-full">
      {results && results.length > 0 ? (
        <>
          <div className="flex items-center gap-4 justify-between">
            <h1 className="text-[var(--text-color)] text-3xl font-medium">
              Recent{" "}
              {from_upload
                ? "Documents"
                : from_photo
                ? "Photos"
                : "AI Searches"}
            </h1>
            {from_upload && (
              <p className="text-base text-font opacity-80">
                Use the following format: lat, lng, title, description, url,
                photo_url
              </p>
            )}
            {from_ai ? (
              <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
                <DialogTrigger asChild>
                  <Button variant="outline">
                    <Plus size={16} />
                    Add place
                  </Button>
                </DialogTrigger>
                <DialogContent>
                  <DialogHeader>Search for places with AI</DialogHeader>
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      handleSearch(input);
                    }}
                  >
                    <div className="py-2 mb-4">
                      <Input
                        type="search"
                        id="ai_place_search"
                        value={input}
                        className="!bg-body dark:!bg-[#101921]"
                        onChange={(event) => setInput(event.target.value)}
                        placeholder="Find me biggest stadiums in US"
                      />
                    </div>
                    <DialogFooter>
                      <Button
                        disabled={isLoading.geolocation}
                        size="sm"
                        className="dark:bg-blue"
                      >
                        {isLoading.geolocation ? (
                          <l-waveform
                            color="var(--text-color)"
                            size={16}
                            stroke={1.5}
                          />
                        ) : (
                          <SearchIcon size={16} />
                        )}
                        Search
                      </Button>
                    </DialogFooter>
                  </form>
                </DialogContent>
              </Dialog>
            ) : (
              <Button
                variant="outline"
                disabled={isLoading.geolocation}
                asChild
              >
                {isLoading.geolocation ? (
                  <div>
                    <l-waveform
                      color="var(--text-color)"
                      size={16}
                      stroke={1.5}
                    />
                  </div>
                ) : (
                  <label
                    className="cursor-pointer"
                    htmlFor="upload-geolocation-input"
                  >
                    <Plus size={16} />
                    Upload {from_upload ? "CSV" : "photo"}
                  </label>
                )}
              </Button>
            )}
            <input
              type="file"
              accept={
                from_upload
                  ? ".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                  : "image/png, image/jpeg, image/jpg"
              }
              className="sr-only"
              id="upload-geolocation-input"
              disabled={isLoading.geolocation}
              onChange={(e) =>
                e.target.files && handleGeolocation(Array.from(e.target.files))
              }
            />
          </div>
          <div className="flex flex-col gap-4 xl:gap-8 xl:grid grid-cols-[repeat(auto-fill,minmax(640px,1fr))] items">
            {results &&
              results.map((item) => (
                <AreaRef
                  {...item}
                  isNew={false}
                  openPopup={(obj) => setDeleteItem(obj)}
                  key={item.id}
                />
              ))}
          </div>
        </>
      ) : from_ai ? (
        <div className="h-full w-full flex flex-col justify-center items-center">
          <form
            className="flex flex-col gap-2 w-full max-w-xl"
            onSubmit={(e) => {
              e.preventDefault();
              handleSearch(input);
            }}
          >
            <h3 className="text-[var(--text-color)] mb-2">
              Search for places with AI
            </h3>
            <input
              className="px-3 py-3 rounded text-base !text-[var(--input-font)] !bg-white dark:!bg-[var(--primary-color-light)]"
              type="search"
              id="ai_place_search"
              value={input}
              onChange={(event) => setInput(event.target.value)}
              placeholder="Find me biggest stadiums in US"
            />
            <div className="flex justify-end mt-2">
              <button
                disabled={isLoading.geolocation}
                className="bg-[var(--primary-color)] rounded max-w-max self-end py-2.5 px-5 text-white text-base disabled:opacity-80 flex items-center gap-2"
              >
                {isLoading.geolocation ? (
                  <l-waveform
                    color="var(--text-color)"
                    size={16}
                    stroke={1.5}
                  />
                ) : (
                  <FontAwesomeIcon icon={faSearch} />
                )}
                Search
              </button>
            </div>
          </form>
        </div>
      ) : (
        <>
          <h1 className="text-xl text-[var(--text-color)] text-center font-medium">
            Upload and geolocate {from_upload ? "CSV" : "photo"}
          </h1>
          <div {...rootProps}>
            <input {...inputProps} />
            {from_upload ? (
              <div className="text-inherit">
                <FontAwesomeIcon
                  icon={faFile as IconProp}
                  size="10x"
                  color="currentColor"
                />
              </div>
            ) : (
              <svg
                width="293"
                height="218"
                viewBox="0 0 293 218"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M264.773 217.946H28.2432C13.1701 217.946 0.951309 205.749 0.951309 190.703V27.2432C0.951309 12.197 13.1701 0 28.2432 0H264.773C279.846 0 292.065 12.197 292.065 27.2432V190.703C292.065 205.749 279.846 217.946 264.773 217.946ZM64.6324 31.7838C47.0473 31.7838 32.7919 46.0138 32.7919 63.5676C32.7919 81.1213 47.0473 95.3514 64.6324 95.3514C82.2175 95.3514 96.4729 81.1213 96.4729 63.5676C96.4729 46.0138 82.2175 31.7838 64.6324 31.7838ZM37.3405 181.622H255.676V118.054L205.916 68.3834C203.252 65.7238 198.932 65.7238 196.267 68.3834L119.216 145.297L87.6514 113.789C84.987 111.129 80.667 111.129 78.002 113.789L37.3405 154.378V181.622Z"
                  fill="white"
                />
              </svg>
            )}
            <p className="text-inherit text-xl transition-colors">
              Drag 'n' drop some files here, or click to select files
            </p>
            {from_upload && (
              <div className="space-y-1 text-inherit">
                <p className="text-inherit text-lg opacity-80 text-center">
                  Use following format:
                </p>
                <p className="text-inherit text-lg opacity-80 text-center">
                  lat, lng, title, description, url, photo_url
                </p>
              </div>
            )}
          </div>
        </>
      )}
      {!!deleteItem && (
        <Popup
          isLoading={isLoading.removal}
          title={
            <span>
              Are you sure you want to delete the{" "}
              <span className="text-[var(--primary-color2)]">
                {deleteItem ? deleteItem.title : ""}
              </span>{" "}
              coordinates?
            </span>
          }
          onClose={() => setDeleteItem(null)}
          onSubmit={handleDelete}
        />
      )}
      {isProgressVisible && (
        <LoadingBar
          height={8}
          color="#ff00cd"
          progress={isLoading.geolocation ? 5 : 100}
        />
      )}
    </section>
  );
}
