/* eslint-disable no-undef */
import { useState, useRef, Fragment, useContext } from "react";
import { GoogleMap, StandaloneSearchBox, Circle } from "@react-google-maps/api";
import CustomMarker from "./CustomMarker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Dark from "./dark.json";
import Bright from "./bright.json";
import { TutorialContext } from "../../providers/tutorial";
import StepPopup from "../tutorial/step";
import { faHandPointDown, faSearch } from "@fortawesome/free-solid-svg-icons";
import { useTheme } from "@/providers/theme";
import { Input } from "../ui/input";
import { Button } from "../ui/button";
import { Search } from "lucide-react";

function Map() {
  const inputRef = useRef(null);
  const [searchBox, setSearchBox] = useState(null);
  const [center, setCenter] = useState({
    lat: 37.23806185281545,
    lng: -99.20252111614502,
  });
  const [markers, setMarkers] = useState([]);
  const [radius, setRadius] = useState(3000);
  const { isFinished, step, changeStep } = useContext(TutorialContext);
  const { isDark } = useTheme();

  const tutorialFirstVisible = !isFinished && step === 1;
  const tutorialSecondVisible = !isFinished && step === 2;

  const onPlacesChanged = () => {
    const query = searchBox.getPlaces();
    if (!query || query.length === 0) return;
    const newLat = query[0].geometry.location.lat();
    const newLng = query[0].geometry.location.lng();
    setCenter({ lat: newLat, lng: newLng });
    setMarkers([
      {
        position: { lat: newLat, lng: newLng },
        circle: {
          options: {
            strokeColor: "#ff00cd",
            fillColor: "#ff00cd",
          },
        },
        id: 1,
        title: query[0].formatted_address,
      },
    ]);
  };

  const onMapClick = (e) => {
    tutorialFirstVisible && changeStep();
    let lat = e.latLng.lat();
    let lng = e.latLng.lng();
    setMarkers(() => [
      {
        position: {
          lat: lat,
          lng: lng,
        },
        circle: {
          options: {
            strokeColor: "#ff00cd",
            fillColor: "#ff00cd",
          },
        },
        id: 1,
        title: null,
      },
    ]);
  };

  const onSBLoad = (ref) => {
    setSearchBox(ref);
  };

  const triggerSearch = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const input = inputRef.current;
    input.focus();
    google.maps.event.trigger(input, "keydown", { keyCode: 13 });
  };

  return (
    <>
      <div
        className={`pl-16 md:pl-4 pr-4 py-6 xl:px-8 relative ${
          tutorialFirstVisible ? "z-20" : ""
        }`}
      >
        <StandaloneSearchBox
          onPlacesChanged={onPlacesChanged}
          onLoad={onSBLoad}
        >
          <div className="relative">
            <Input
              startContent={<Search size={14} />}
              type="text"
              placeholder="Search for a place..."
              ref={inputRef}
            />
            <Button
              className="absolute right-0 top-0 bottom-0"
              onClick={triggerSearch}
            >
              Search
            </Button>
          </div>
        </StandaloneSearchBox>
      </div>
      <div className={`flex-1 w-full bg-[var(--body-color)] z-20 relative`}>
        <GoogleMap
          mapContainerStyle={{ height: "100%", width: "100%" }}
          center={center}
          zoom={5}
          onClick={onMapClick}
          options={{
            styles: isDark ? Dark : Bright,
            disableDefaultUI: true,
          }}
        >
          {markers &&
            markers.map((marker, key) => (
              <Fragment key={key}>
                <CustomMarker
                  id={marker.id}
                  position={marker.position}
                  locationTitle={marker.title}
                  radius={radius}
                  setRadius={setRadius}
                  tutorialVisible={tutorialSecondVisible}
                />
                {marker.circle && (
                  <Circle
                    center={{
                      lat: marker.position.lat,
                      lng: marker.position.lng,
                    }}
                    radius={radius}
                    options={marker.circle.options}
                  />
                )}
              </Fragment>
            ))}
        </GoogleMap>
        {tutorialFirstVisible && (
          <StepPopup
            title="Setting a place"
            paragraph="Now you'll need to define a place in the bounds of which an objects are going to be fetched"
            actionText="Click anywhere on the map"
            position="right-0 bottom-0"
            icon={faHandPointDown}
            iconSize="2x"
            iconPosition="bottom-[105%] right-[105%] rotate-[120deg]"
          />
        )}
      </div>
      {(tutorialFirstVisible || tutorialSecondVisible) && (
        <div className="fixed bg-black/40 backdrop-blur-[2px] inset-0 z-10" />
      )}
    </>
  );
}
export default Map;
