import axios from "axios";
import useSWR, { SWRConfiguration } from "swr";

async function getPersonInvestigated(
  person_id: number
): Promise<PersonInvestigated> {
  const { data } = await axios.get(`/getPersonInvestigated/${person_id}`);
  return data.objects;
}

const usePersonInvestigated = (
  id: string,
  config?: SWRConfiguration<PersonInvestigated>
) =>
  useSWR(
    isNaN(parseInt(id)) ? null : ["people-investigated", parseInt(id)],
    ([_, person_id]) => getPersonInvestigated(person_id),
    config
  );

export default usePersonInvestigated;
