const google = (window.google = window.google ? window.google : {});

export function getCenterFromBounds(bounds) {
  const { lat, lng } = bounds.getCenter();
  return {
    lat: lat(),
    lng: lng(),
  };
}

export async function getAnalysis(token, body) {
  const response = await fetch(
    `${process.env.REACT_APP_API_SERVER_URL}/api/analyse/`,
    {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    }
  );
  const data = await response.json();
  return data;
}

export async function postSearch(token, body) {
  const response = await fetch(
    `${process.env.REACT_APP_API_SERVER_URL}/api/search/`,
    {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    }
  );
  const data = await response.json();

  return { ...data, status: response.status };
}

export async function getCoordinates(
  token,
  locationSearchId,
  from_photo = false,
  from_upload = false,
  from_ai = false,
  module,
  search
) {
  let url = `${process.env.REACT_APP_API_SERVER_URL}/api/getCoordinates`;

  if (locationSearchId) {
    url += `/${locationSearchId}`;
  }

  if (module) {
    url += `/${module}`;
  }

  if (from_photo) {
    url += "?from_photo=true";
  } else if (from_upload) {
    url += "?from_upload=true";
  }
  else if (from_ai) {
    url += "?from_ai=true"
  }

  if (search) {
    url += search;
  }

  const response = await fetch(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  if (!response.ok) {
    return;
  }
  const coordinates = await response.json();
  return coordinates;
}

export async function deleteCoordinates(token, id) {
  const request_data = { id };
  const response = await fetch(
    `${process.env.REACT_APP_API_SERVER_URL}/api/removeCoordinates/`,
    {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(request_data),
    }
  );
  return response.status;
}

export async function getAllResults(token, workspaceId) {
  const response = await fetch(
    `${process.env.REACT_APP_API_SERVER_URL}/api/showAll/${workspaceId}/`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  const data = await response.json();

  if (data.error) {
    // check if it returns "error" key or "task_id" key
    return {
      result: null,
      error: data.message,
    };
  }

  if (!response.ok) {
    return {
      result: null,
      error: "Unknown error, please contact support",
    };
  }

  return {
    result: data.task_id,
  };
}

export async function getProgress(token, locationSearchId) {
  const response = await fetch(
    `${process.env.REACT_APP_API_SERVER_URL}/api/getProgress/${locationSearchId}`,
    {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }
  );

  if (!response.ok) {
    throw new Error("Network response was not ok");
  }

  const data = await response.json();
  return data;
}

export async function getResults(token, locationSearchId) {
  const response = await fetch(
    `${process.env.REACT_APP_API_SERVER_URL}/api/getSearchResults/${locationSearchId}/`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  const results = await response.json();
  return results;
}

export async function getErrors(token) {
  const response = await fetch(
    `${process.env.REACT_APP_API_SERVER_URL}/api/checkErrors/`,
    {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }
  );

  const data = await response.json();
  return data.errors;
}

export async function getSearchResults(
  token,
  query,
  person_or_item,
  item_type,
  workspace_id
) {
  // if (query.length < 4) return;
  const response = await fetch(
    `${process.env.REACT_APP_API_SERVER_URL}/api/searchDatabase/`,
    {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        query,
        person_or_item,
        item_type,
        workspace_id,
      }),
    }
  );
  const data = await response.json();
  return data;
}

export const getDrawingManagerOptions = (color, drawingControl = true) => {
  const fillOptions = {
    fillColor: color,
    strokeColor: color,
    strokeOpacity: 0.8,
    fillOpacity: 0.08,
    clickable: true,
  };
  return {
    drawingControl,
    drawingControlOptions: {
      position: google.maps.ControlPosition.TOP_RIGHT,
      drawingModes: ["circle", "polygon", "polyline", "rectangle"],
    },
    circleOptions: fillOptions,
    polygonOptions: fillOptions,
    rectangleOptions: fillOptions,
    polylineOptions: { strokeColor: color, clickable: true },
  };
};

export const getRoute = async (token, { type, item_id }) => {
  const response = await fetch(
    `${process.env.REACT_APP_API_SERVER_URL}/api/${
      type === "planes" ? "getPlaneRoute" : "getVesselRoute"
    }/${item_id}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  const data = await response.json();
  return data;
};

// ?query=${query}&person_or_item=${personOrItem}${
//   itemType ? "&item_type=" + itemType : ""
// }${workspaceId ? "&workspace=" + workspaceId : ""}

export async function getUploadItems(token, workspace_id, { from_upload, from_photo, from_ai }) {
  // if (query.length < 4) return;
  const response = await fetch(
    `${process.env.REACT_APP_API_SERVER_URL}/api/${
      from_upload
    ? "getUploadedItems"
    : from_photo
        ? "getGeolocatedPhotos"
        : from_ai
            ? "getAiPlaces"
            : "getAiPlaces"
    }` + workspace_id,
    {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }
  );
  const data = await response.json();
  return data;
}

export async function postOSMSearch(token, body) {
  const response = await fetch(
    `${process.env.REACT_APP_API_SERVER_URL}/api/searchOsm/`,
    {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    }
  );
  const data = await response.json();
  return { ...data, status: response.status };
}
