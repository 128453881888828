import axios from "axios";
import useSWR, { SWRConfiguration } from "swr";

async function getUploadedItems(
  { from_upload, from_photo, from_ai }: Pick<Area, "from_photo" | "from_upload" | "from_ai" >,
  workspace_id: number
) {
  const { data } = await axios.get(
    `/${
      from_upload
        ? "getUploadedItems"
        : from_photo
          ? "getGeolocatedPhotos"
          : from_ai
            ? "getAiPlaces"
            : "defaultEndpoint" // Optional: Handle cases where none is true
    }/${workspace_id}`
  );
  return data;
}

const useUploadedItems = (
  workspace_id: number,
  from?: Pick<Area, "from_photo" | "from_upload" | "from_ai" >,
  config?: SWRConfiguration
) =>
  useSWR(
    from && Object.values(from).some((v) => !!v)
      ? ["uploaded-items", from, workspace_id]
      : null,
    ([_k, from, workspace_id]) => getUploadedItems(from, workspace_id),
    {
      ...config,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  );

export default useUploadedItems;
