import { useEffect, useMemo, useRef, useState } from "react";
import MapContainer from "../components/map/MapContainer";
import LoadingBar from "react-top-loading-bar";
import { SearchIcon } from "lucide-react";
import Legend from "@/components/search/legend";
import { Input } from "@/components/ui/input";

import Menu from "@/components/search/menu/wrapper";
import { Switch } from "@/components/ui/switch";
import { useTheme } from "@/providers/theme";
import useFilters from "@/providers/search/filters";
import { StandaloneSearchBox } from "@react-google-maps/api";
import useMap from "@/hooks/useMap";
import { cn } from "@/utils/cn";
import Filter from "@/components/search/filter";
import { SEARCH_OPTIONS } from "@/const/search";
import { useParams } from "react-router-dom";
import useArea from "@/lib/search/area";
import useUploadedItems from "@/lib/search/uploaded-items";

export default function Search() {
  const [showOnly, setShowOnly] = useState(
    SEARCH_OPTIONS.flatMap((item) => item.options)
  );
  const [selectedValues, setSelectedValues] = useState({ "Social Media": [] });
  const [isMenuOpen, setIsMenuOpen] = useState(true);
  const [place, setPlace] = useState({});
  const mapBounds = useRef({});
  const zoom = useRef();
  const { isDark, changeTheme } = useTheme();
  const [searchBox, setSearchBox] = useState(null);
  const { workspace_id } = useParams();
  const [itemsBounds, setItemsBounds] = useState(null);
  const { data: area, isLoading } = useArea(workspace_id);

  useUploadedItems(
    workspace_id,
    area
      ? {
          from_photo: area.from_photo,
          from_upload: area.from_upload,
          from_ai: area.from_ai,
        }
      : undefined,
    {
      onSuccess: (data) => {
        if (!Array.isArray(data)) return;
        setMarkerList((prev) => [...prev, ...data]);
        if (
          data.length === 0 ||
          !Array.isArray(data[0].objects) ||
          data[0].objects.length === 0
        )
          return;
        const itemsBounds = new window.google.maps.LatLngBounds();
        data[0].objects.forEach((obj) => {
          if (!obj.location) return;
          itemsBounds.extend(obj.location);
        });
        setItemsBounds(itemsBounds);
      },
    }
  );

  const { map } = useMap();
  // const { ref, isMenuVisible, setIsMenuVisible } = useMenu();
  const {
    error,
    progress,
    setLoadingRequests,
    searchId,
    setSearchId,
    setMarkerList,
    loadingRequests,
    markerList,
    news,
    setWeather,
    setNews,
  } = useFilters();

  const availableToFilter = useMemo(() => {
    const prevSelectedValues = { ...selectedValues };
    markerList.forEach((marker) => {
      // get group title
      const category = SEARCH_OPTIONS.find((item) =>
        item.options.some((opt) => opt.value === marker.type)
      );
      if (!category) return;
      const referenceInSelectedValues = [
        ...(prevSelectedValues[category.title] || []),
      ];
      if (referenceInSelectedValues.some((opt) => opt.value === marker.type))
        return;
      prevSelectedValues[category.title] = [
        ...referenceInSelectedValues,
        category.options.find((opt) => opt.value === marker.type),
      ];
    });
    return prevSelectedValues;
  }, [selectedValues, markerList]);
  // useEffect(() => {
  //   if (!workspace) return;
  //   (async () => {
  //     try {
  //       const token = await getAccessTokenSilently();
  //       const coordinatesData = await getCoordinates(token, workspace.id);
  //       const from_upload = coordinatesData.from_upload;
  //       const from_photo = coordinatesData.from_photo;

  //       const initialBounds = {
  //         north: coordinatesData.north_lat,
  //         south: coordinatesData.south_lat,
  //         east: coordinatesData.east_lng,
  //         west: coordinatesData.west_lng,
  //       };

  //       setBounds(initialBounds);

  //       if (!from_photo && !from_upload) {
  //         setIsLoading(false);
  //         return;
  //       }

  //       const data = await getUploadItems(token, workspace.id, {
  //         from_upload,
  //       });

  //       if (Array.isArray(data)) {
  //         setMarkerList((prev) => [...prev, ...data]);
  //         if (
  //           data.length === 0 ||
  //           !Array.isArray(data[0].objects) ||
  //           data[0].objects.length === 0
  //         )
  //           return;
  //         const itemsBounds = new window.google.maps.LatLngBounds();
  //         data[0].objects.forEach((obj) => {
  //           if (!obj.location) return;
  //           itemsBounds.extend(obj.location);
  //         });
  //         setItemsBounds(itemsBounds);
  //       }
  //       setIsLoading(false);
  //     } catch (e) {
  //       console.error(e);
  //     }
  //   })();
  // }, [getAccessTokenSilently, workspace, setMarkerList]);

  useEffect(() => {
    if (!map || !itemsBounds) return;
    map.fitBounds(itemsBounds);
  }, [map, itemsBounds]);

  if (error) return <div>Error: {error.message}</div>;

  const onPlacesChanged = () => {
    const query = searchBox.getPlaces();
    if (!query || query.length === 0) return;
    const newLat = query[0].geometry.location.lat();
    const newLng = query[0].geometry.location.lng();
    // setCenter({ lat: newLat, lng: newLng });
    setPlace({ lat: newLat, lng: newLng });
  };

  return (
    <section className="flex flex-col w-full h-full pt-2">
      <div className="relative flex items-center justify-between px-6 mb-2">
        <h1 className="text-xl text-[var(--text-color)] text-center font-medium">
          {area?.title}
        </h1>
        <div className="flex-1 max-w-md">
          <StandaloneSearchBox
            onPlacesChanged={onPlacesChanged}
            onLoad={(box) => setSearchBox(box)}
            bounds={
              area
                ? {
                    north: area.north_lat,
                    south: area.south_lat,
                    east: area.east_lng,
                    west: area.west_lng,
                  }
                : undefined
            }
          >
            <Input
              placeholder="Search"
              startContent={<SearchIcon size={16} />}
            />
          </StandaloneSearchBox>
        </div>
        <div className="flex items-center gap-4">
          <Filter
            availableValues={availableToFilter}
            selectedValues={showOnly}
            setSelectedValues={setShowOnly}
          />
          <div className="flex items-center gap-2">
            <Switch
              className="!bg-white dark:!bg-pink"
              checked={isDark}
              onCheckedChange={changeTheme}
            />
            <span className="text-[var(--text-color)] text-base opacity-80e">
              Dark mode
            </span>
          </div>
          <Legend />
        </div>
      </div>
      <div
        className={cn(
          "flex-1 grid transition-[grid-template-columns] overflow-hidden",
          isMenuOpen ? "grid-cols-[280px_1fr]" : "grid-cols-[0px_1fr]"
        )}
      >
        <Menu
          selected={selectedValues}
          onSelectionChange={(type, values) =>
            setSelectedValues((prev) => ({ ...prev, [type]: values }))
          }
          place={place}
          mapBounds={mapBounds.current}
          isMenuOpen={isMenuOpen}
          toggleMenu={() => setIsMenuOpen((prev) => !prev)}
          zoom={zoom}
        />
        {isLoading ? (
          <div className="flex items-center justify-center">
            <l-waveform size={48} color="var(--text-color)" />
          </div>
        ) : (
          <MapContainer
            place={place}
            changePlace={(coords) => setPlace(coords)}
            changeMapBounds={(bnds) => {
                const zoom_level = map.getZoom();
                zoom.current = zoom_level;

              const ne = bnds.getNorthEast();
              const sw = bnds.getSouthWest();
              mapBounds.current = {
                ne_lat: ne.lat(),
                ne_lng: ne.lng(),
                sw_lat: sw.lat(),
                sw_lng: sw.lng(),
              };
            }}
            showOnly={showOnly}
            markers={markerList}
            setMarkers={setMarkerList}
            mapBounds={mapBounds.current}
            setSearchId={setSearchId}
            setWeather={setWeather}
            setNews={setNews}
            news={news}
            loadingRequests={loadingRequests}
            zoom={zoom.current}
            changeLoadingRequest={(key, value) =>
              setLoadingRequests((prev) => ({ ...prev, [key]: value }))
            }
          />
        )}
      </div>
      <LoadingBar
        height={8}
        color="#ff00cd"
        progress={progress.value ? progress.value : searchId.value ? 5 : 0}
      />
    </section>
  );
}
