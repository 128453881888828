import { Link } from "react-router-dom";
import { TILE_ATTRIBUTE_EXCLUSIONS } from "../../const/tile-items";
import AttributeField from "./AttributeField";

export default function InfoWindowContent({
  obj,
  prioritize = [],
  onlyPriorities = false,
}) {
  const isLink = !!(obj.associated_item?.location?.url || obj.id);
  let filteredKeys = Object.keys(obj);
  if (onlyPriorities) {
    filteredKeys = filteredKeys.filter((key) => prioritize.includes(key));
  } else {
    filteredKeys = filteredKeys.filter(
      (key) =>
        (typeof obj[key] !== "object" &&
          !(
            TILE_ATTRIBUTE_EXCLUSIONS[obj.type ? obj.type : obj.searched] || []
          ).includes(key)) ||
        prioritize.includes(key)
    );
    filteredKeys = prioritize
      ? filteredKeys.sort(
          (a, b) => prioritize.indexOf(b) - prioritize.indexOf(a)
        )
      : filteredKeys;
    filteredKeys = filteredKeys.slice(0, 4);
  }
  return isLink ? (
    <Link
      to={obj.associated_item?.location?.url || obj.url || "#"}
      className="bg-[var(--body-color)] overflow-hidden hover:opacity-80 opacity-100 transition-opacity max-w-sm block"
    >
      {obj.photo_url && (
        <img
          className="w-full h-[100px] object-cover"
          src={obj.photo_url}
          alt=""
        />
      )}
      <div className="grid grid-cols-2 gap-2 p-4">
        {filteredKeys.map((key) => (
          <AttributeField
            name={key}
            value={obj[key]}
            className="line-clamp-1"
            key={key}
          />
        ))}
      </div>
    </Link>
  ) : (
    <div className="bg-[var(--body-color)] overflow-hidden max-w-sm block">
      {obj.photo_url && (
        <img
          className="w-full h-[100px] object-cover"
          src={obj.photo_url}
          alt=""
        />
      )}
      <div className="grid grid-cols-2 gap-2 p-4">
        {filteredKeys.map((key) => (
          <AttributeField
            name={key}
            value={obj[key]}
            className="line-clamp-1"
            key={key}
          />
        ))}
      </div>
    </div>
  );
}
