import { useEffect, useMemo, useState } from "react";
import {
  DirectionsRenderer,
  InfoWindow,
  Marker,
  PolygonF,
} from "@react-google-maps/api";
import { Link, useParams } from "react-router-dom";
import {
  DIRECTIONS_MODULES,
  ICON_TYPES,
  SEARCH_OPTIONS,
} from "../const/search";
import { POLYGON_MODULES } from "../const/general";
import CustomMarker from "../components/map/CustomMarker";
import AuthorInfo from "../components/single-item/author-info";
import ArrowRightIcon from "../const/search/imgs/arrow-right";
import useAIGeolocation from "../hooks/single-item/useAIGeolocation";
import InfoWindowContent from "../components/tile-items/InfoWindowContent";
import Map from "../components/map/Map";
import useLocation from "../hooks/single-item/useLocation";
import ossIcon from "../const/search/imgs/oss.png";
import useFaceSearch from "../hooks/single-item/useFaceSearch";
import FaceSearch from "../components/single-item/face-search";
import LoadingBar from "react-top-loading-bar";
import useMap from "../hooks/useMap";
import { hashtagPages } from "./tile-items";
import { Card, CardTitle } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { LocateFixed } from "lucide-react";
import Notes from "@/components/single-item/notes";
import ObjectRef from "@/components/tile-items/ObjectRef";
import Actions from "@/components/single-item/actions";
import { cn } from "@/utils/cn";
import useNearby from "@/hooks/single-item/useNearby";
import Nearby from "@/components/single-item/nearby";
import useArea from "@/lib/search/area";
import useItem from "@/lib/single-item";

export default function SingleItem() {
  const { map } = useMap();
  const [directions, setDirections] = useState(null);
  const { type, item_id, workspace_id } = useParams();
  // const [item, setItem] = useState({});
  const [activeTab, setActiveTab] = useState("item");
  const {
    analysis,
    isDisabled: areNearbyDisabled,
    results: nearby,
    onSearch: searchNearby,
    areLoading: areNearbyLoading,
  } = useNearby(() => setActiveTab("nearby"));
  const {
    isLoading: isGeolocationLoading,
    details: aiGeolocationDetails,
    infoWindow,
    onInfoWindowLoad,
    handleGeolocate,
  } = useAIGeolocation();
  const { data: area, isLoading: isAreaLoading } = useArea(workspace_id);
  const {
    progress,
    isDisabled: isFaceSearchDisabled,
    setIsDisabled: setIsFaceSearchDisabled,
    isLoading: isFaceSearchLoading,
    photos,
    setPhotos,
    handleSearch: handleFaceSearch,
  } = useFaceSearch(() => setActiveTab("face-search"));
  const { data: item, isLoading } = useItem(item_id, type, workspace_id, {
    onSuccess: (data) => {
      if (data.faces) {
        setPhotos(data.faces);

        setIsFaceSearchDisabled(false);
      } else {
        setIsFaceSearchDisabled(true);
      }
      if (!DIRECTIONS_MODULES.includes(type) || !data) return;
      const waypoints = data.center_position
        ? [
            {
              location: data.center_position,
              stopover: true,
            },
          ]
        : [];
      const directionsService = new window.google.maps.DirectionsService();
      directionsService.route(
        {
          origin: data.start_position,
          destination: data.stop_position,
          travelMode: "WALKING",
          waypoints,
        },
        (result, status) => {
          if (status === window.google.maps.DirectionsStatus.OK) {
            setDirections(result);
          }
        }
      );
    },
  });
  const {
    isLoading: isLocationLoading,
    location,
    // bounds,
    chosenPlace,
    onPlaceChange,
    handleChange,
    // onSearch,
    setBounds,
    // onSearchBoxLoad,
  } = useLocation(map, item?.location);

  useEffect(() => {
    setActiveTab("item");
  }, [item_id, type, workspace_id]);

  useEffect(() => {
    if (!map || !location) return;
    const bounds = new window.google.maps.LatLngBounds();
    location && bounds.extend(location);
    aiGeolocationDetails &&
      bounds.extend({
        lat: aiGeolocationDetails.lat,
        lng: aiGeolocationDetails.lng,
      });
    map.fitBounds(bounds);
  }, [map, aiGeolocationDetails, location]);

  const authorDataVisible = useMemo(
    () =>
      SEARCH_OPTIONS.find((item) => {
        const foundType = item.options.find((item) => item.value === type);
        return foundType && foundType.authorData !== false;
      })?.authorData,
    [type]
  );

  if (isLoading || isAreaLoading) {
    return (
      <div className="w-full h-screen flex items-center justify-center">
        <l-waveform size={48} color="var(--text-color)" />
      </div>
    );
  }

  const routeVisible = directions && DIRECTIONS_MODULES.includes(type);

  const polygonModule = POLYGON_MODULES.find((item) => item.value === type);

  const tabs = [
    {
      value: "item",
      label: "Item",
    },
    ...(nearby.length > 0
      ? [
          {
            value: "nearby",
            label: "Nearby",
          },
        ]
      : []),
    ...(photos.length > 0
      ? [
          {
            value: "face-search",
            label: "Face Search",
          },
        ]
      : []),
  ];

  return (
    <section className="h-full flex flex-col gap-2 px-4 md:px-8 pt-4 pb-8 w-full">
      <div
        className={cn(
          "sticky bg-[var(--body-color)] h-max z-40 py-4",
          item.title ? "-top-11" : "top-0"
        )}
      >
        <div className="flex items-end gap-4 justify-between">
          <div className="flex flex-col gap-4 text-[#98A2B3] dark:text-[#CECFD2] stroke-[#98A2B3]/60 dark:stroke-[#CECFD2]/60">
            <div className="flex items-center gap-3 text-base">
              <Link
                className="py-1 px-2 rounded hover:bg-slate-300 dark:hover:bg-[#1F242F]"
                to="/database"
              >
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5.66667 13.1663H12.3333M8.18141 1.30297L2.52949 5.6989C2.15168 5.99276 1.96278 6.13968 1.82669 6.32368C1.70614 6.48667 1.61633 6.67029 1.56169 6.86551C1.5 7.0859 1.5 7.32521 1.5 7.80384V13.833C1.5 14.7664 1.5 15.2331 1.68166 15.5896C1.84144 15.9032 2.09641 16.1582 2.41002 16.318C2.76654 16.4996 3.23325 16.4996 4.16667 16.4996H13.8333C14.7668 16.4996 15.2335 16.4996 15.59 16.318C15.9036 16.1582 16.1586 15.9032 16.3183 15.5896C16.5 15.2331 16.5 14.7664 16.5 13.833V7.80384C16.5 7.32521 16.5 7.0859 16.4383 6.86551C16.3837 6.67029 16.2939 6.48667 16.1733 6.32368C16.0372 6.13968 15.8483 5.99276 15.4705 5.69891L9.81859 1.30297C9.52582 1.07526 9.37943 0.9614 9.21779 0.917634C9.07516 0.879018 8.92484 0.879018 8.78221 0.917634C8.62057 0.9614 8.47418 1.07526 8.18141 1.30297Z"
                    stroke="currentColor"
                    strokeWidth="1.66667"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </Link>
              <ArrowRightIcon />
              <Link
                className="py-1 px-2 rounded hover:bg-slate-300 dark:hover:bg-[#1F242F]"
                to={`/${workspace_id}/search`}
              >
                {area?.title.length > 16
                  ? area.title.substring(0, 16) + "..."
                  : area?.title || "Workspace"}
              </Link>
              <ArrowRightIcon />
              <Link
                className="py-1 px-2 rounded hover:bg-slate-300 dark:hover:bg-[#1F242F]"
                to={`/${workspace_id}/module/${type}`}
              >
                {type.charAt(0).toUpperCase() + type.substring(1)}
              </Link>
              <ArrowRightIcon />
              <Link
                className="text-font py-1 px-2 rounded bg-slate-300 dark:bg-[#1F242F] max-w-xs line-clamp-1"
                to={`/${workspace_id}/module/${type}/item/${item_id}`}
              >
                {item.title || "Item"}
              </Link>
            </div>
            {item.title && (
              <h1 className="text-[var(--text-color)] font-medium text-3xl sm:text-4xl line-clamp-2 max-w-7xl">
                {item.title}
              </h1>
            )}
          </div>
          {tabs.length > 0 && (
            <div className="flex items-center rounded-lg bg-[var(--sidebar-color)] overflow-hidden max-w-max border border-slate-300 dark:border-[#333741]">
              {tabs.map((tab) => (
                <button
                  onClick={() => setActiveTab(tab.value)}
                  className={cn(
                    "px-4 py-2 font-semibold text-lg flex items-center gap-2.5 border-r border-slate-300 dark:border-[#333741] last:border-r-0",
                    activeTab === tab.value
                      ? "text-[var(--primary-color2)] bg-pink/5 dark:bg-[#1F242F]"
                      : "text-[#98A2B3] dark:text-[#CECFD2]"
                  )}
                  key={tab.value}
                >
                  {activeTab === tab.value && (
                    <div className="w-2 h-2 rounded-full bg-[var(--primary-color2)]" />
                  )}
                  {tab.label}
                </button>
              ))}
            </div>
          )}
        </div>
      </div>
      {activeTab === "item" ? (
        <div className="flex flex-col lg:grid grid-cols-2 gap-6">
          <ObjectRef
            {...item}
            exclusionsType="single"
            workspaceId={workspace_id}
            workspaceTitle={area?.title}
            is_favorite={item?.is_favorite}
            simplified
            showTypeIcon
          >
            <Actions
              item={item}
              handleGeolocate={handleGeolocate}
              searchNearby={searchNearby}
              handleFaceSearch={handleFaceSearch}
              isLoading={{
                geolocation: isGeolocationLoading,
                faceSearch: isFaceSearchLoading,
                nearby: areNearbyLoading,
              }}
              isDisabled={{
                faceSearch: isFaceSearchDisabled,
                nearby: areNearbyDisabled,
              }}
            />
          </ObjectRef>
          {authorDataVisible && <AuthorInfo item={item} />}
          <Card className={authorDataVisible ? "col-span-2" : "col-span-1"}>
            <div className="flex items-center gap-4">
              <CardTitle>Map</CardTitle>
              {hashtagPages.includes(type) && (
                <p className="text-base text-[var(--text-color)] opacity-80">
                  Location is not accurate, search was done by hashtag
                </p>
              )}
            </div>
            <div className="flex-1 min-h-[560px] relative flex flex-col items-center">
              <Map
                center={location}
                zoom={15}
                setPlace={onPlaceChange}
                changeMapBounds={setBounds}
                defaultOptions={{
                  mapTypeId: window.google.maps.MapTypeId.SATELLITE,
                }}
              >
                {polygonModule && item.polygon ? (
                  <PolygonF
                    paths={JSON.parse(item.polygon)}
                    options={{
                      fillColor: polygonModule.color,
                      fillOpacity: 0.7,
                      strokeColor: polygonModule.color,
                    }}
                    key={item.id}
                  />
                ) : routeVisible ? (
                  <DirectionsRenderer directions={directions} />
                ) : (
                  location && (
                    <CustomMarker {...item} type={type} position={location} />
                  )
                )}
                <Marker
                  visible={!!aiGeolocationDetails}
                  onClick={() => infoWindow && infoWindow.open()}
                  position={
                    aiGeolocationDetails
                      ? {
                          lat: aiGeolocationDetails.lat,
                          lng: aiGeolocationDetails.lng,
                        }
                      : undefined
                  }
                  icon={{
                    scaledSize: new window.google.maps.Size(30, 30),
                    url: ICON_TYPES[type],
                  }}
                  animation={window.google.maps.Animation.BOUNCE}
                >
                  <InfoWindow onLoad={onInfoWindowLoad}>
                    <InfoWindowContent
                      obj={aiGeolocationDetails || {}}
                      prioritize={["country", "province", "city"]}
                      onlyPriorities
                    />
                  </InfoWindow>
                </Marker>
                <Marker
                  visible={!!chosenPlace}
                  position={chosenPlace}
                  icon={{
                    url: ossIcon,
                    scaledSize: new window.google.maps.Size(60, 60), // Set the size of your icon
                  }}
                />
              </Map>
              {chosenPlace && (
                <Button
                  onClick={handleChange}
                  disabled={isLocationLoading}
                  className="absolute bottom-0"
                  variant="outline"
                >
                  <LocateFixed size={14} />
                  Change location
                </Button>
              )}
            </div>
          </Card>
        </div>
      ) : activeTab === "face-search" ? (
        <FaceSearch photos={photos} />
      ) : (
        <Nearby analysis={analysis} items={nearby} item={item} />
      )}
      <Notes defaultValue={item?.notes || ""} />
      <LoadingBar height={8} color="#ff00cd" progress={progress || 0} />
    </section>
  );
}
