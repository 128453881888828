import axios from "axios";
import useSWR from "swr";

async function getPeopleInvestigated(): Promise<PersonInvestigated[]> {
  const { data } = await axios.get("/getPeopleInvestigated/");
  return data.objects;
}

const usePeopleInvestigated = () =>
  useSWR(["people-investigated"], () => getPeopleInvestigated());

export default usePeopleInvestigated;
