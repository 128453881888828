import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "../ui/accordion";
import { useParams } from "react-router-dom";
import { ICON_TYPES } from "@/const/search";
import ObjectRef from "../tile-items/ObjectRef";
import usePersonInvestigated from "@/lib/single-item/person-investigation";
import { ScrollArea } from "../ui/scroll-area";

const formatDateToUTC = (date: Date) => {
  const month = date.getUTCMonth() + 1;
  const day = date.getUTCDate();
  const dateStr = `${date.getUTCFullYear()}-${
    month < 10 ? "0" + month : month
  }-${day < 10 ? "0" + day : day}`;
  return dateStr;
};

type Props = { onPostOpen?: (value: string) => void };

export default function PersonActivity({ onPostOpen }: Props) {
  const { person_id } = useParams() as Readonly<{ person_id: string }>;
  const { data } = usePersonInvestigated(person_id);
  const dates = data
    ? [
        ...(data.posts || []),
        ...(data.associated_item ? [data.associated_item] : []),
      ].reduce((prev, curr) => {
        if (!curr.timestamp) return prev;
        const date = new Date(curr.timestamp);
        const dateStr = formatDateToUTC(date);
        if (prev.includes(dateStr)) return prev;
        return [...prev, dateStr];
      }, [] as string[])
    : [];
  return (
    <div className="absolute inset-0 w-full h-full">
      <ScrollArea className="h-full">
        <Accordion defaultValue={dates} type="multiple">
          {dates.map((date) => (
            <DateRef date={date} onPostOpen={onPostOpen} key={date} />
          ))}
        </Accordion>
      </ScrollArea>
    </div>
  );
}

interface DateProps extends Props {
  date: string;
}

const DateRef = ({ date, onPostOpen }: DateProps) => {
  const { person_id } = useParams() as Readonly<{ person_id: string }>;
  const { data } = usePersonInvestigated(person_id);
  const objects = data
    ? [
        ...(data.posts || []),
        ...(data.associated_item ? [data.associated_item] : []),
      ]
        .filter(
          (obj) =>
            obj.timestamp && formatDateToUTC(new Date(obj.timestamp)) === date
        )
        .sort(
          (a, b) =>
            new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime()
        )
    : [];

  if (objects.length === 0) return <></>;

  return (
    <AccordionItem className="px-4" value={date}>
      <AccordionTrigger className="flex items-center gap-2">
        <span className="text-font opacity-80 text-base">
          {new Intl.DateTimeFormat("en-US", {
            dateStyle: "long",
            timeZone: "UTC",
          }).format(new Date(date).getTime())}
        </span>
        <div className="bg-font opacity-75 rounded-full h-0.5 w-0.5" />
        <span className="text-font opacity-80 text-base">{objects.length}</span>
        <div className="flex-1 mx-2 bg-font opacity-10 h-px rounded-full" />
        <div className="flex"></div>
      </AccordionTrigger>
      <AccordionContent>
        <Accordion type="single" collapsible onValueChange={onPostOpen}>
          {objects.map((obj) => (
            <ItemRef {...obj} key={`timeline-${obj.type}-${obj.id}`} />
          ))}
        </Accordion>
      </AccordionContent>
    </AccordionItem>
  );
};

const ItemRef = (obj: SearchObject) => {
  const { id, type, title, name, timestamp } = obj;
  return (
    <AccordionItem value={`${type}-${id}`} className="border-0">
      <AccordionTrigger iconPosition="end" className="py-0">
        <div className="flex items-center gap-4">
          <div className="flex items-center justify-center relative h-12">
            <div className="flex items-center justify-center bg-sidebar h-6 rounded-full relative z-10">
              <div className="border border-font opacity-20 rounded-full h-2 w-2"></div>
            </div>
            <div className="absolute bg-slate-200 dark:bg-[#1F242F] inset-y-0 h-full w-px"></div>
          </div>
          <div className="h-6 w-6 shrink-0">
            <img
              className="w-full h-full object-cover object-center"
              src={ICON_TYPES[type as keyof typeof ICON_TYPES]}
              alt=""
            />
          </div>
          <div className="flex items-center gap-2">
            <h4 className="max-w-xs text-left line-clamp-1 text-base text-font">
              {title || name}
            </h4>
            <h5 className="text-font opacity-60 text-base min-w-max">
              {new Intl.DateTimeFormat("en-US", {
                timeStyle: "medium",
                timeZone: "UTC",
              }).format(new Date(timestamp))}
            </h5>
          </div>
        </div>
      </AccordionTrigger>
      <AccordionContent>
        <ObjectRef {...obj} />
      </AccordionContent>
    </AccordionItem>
  );
};

// <div className="flex items-center gap-3">
//       <Button size="sm" asChild className="h-8">
//         <Link to={`/${workspace_id}/module/${type}/item/${id}`}>Details</Link>
//       </Button>
//       <Button
//         variant="outline"
//         size="sm"
//         onClick={() => {
//           if (!map) return;
//           map.panTo(location);
//           map.setZoom(20);
//         }}
//       >
//         Zoom on map
//       </Button>
//     </div>
//     </div>
