type Props = {
  currentPage: number;
  totalPage: number;
  onPageChange: (value: number) => void;
};

export default function Pagination({
  currentPage,
  totalPage,
  onPageChange,
}: Props) {
  const pageNumbers = [...Array(totalPage).keys()].map((num) => num + 1);
  return (
    <nav>
      <ul className="pagination">
        {pageNumbers.map((page) => (
          <li
            key={page}
            className={`page-item ${page === currentPage ? "active" : ""}`}
          >
            <button
              className="page-link transition-colors"
              onClick={() => onPageChange(page)}
            >
              {page}
            </button>
          </li>
        ))}
      </ul>
    </nav>
  );
}
