import { Search, Trash2 } from "lucide-react";
import { Link } from "react-router-dom";
import { Card } from "../ui/card";
import { Button } from "../ui/button";
import AuthorRef from "../single-item/author-ref";
import { ICON_TYPES } from "@/const/search";
import axios from "axios";
import { useState } from "react";
import usePeopleInvestigated from "@/lib/single-item/people-investigated";

export default function PersonRef(props: PersonInvestigated) {
  const [isLoading, setIsLoading] = useState(false);
  const { id, realname, type, username, photo_url } = props;
  const { mutate } = usePeopleInvestigated();

  async function handleDelete() {
    setIsLoading(true);
    try {
      await axios.get(`/deletePerson/${id}`);
      mutate();
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
    }
  }

  return (
    <Card className="gap-0">
      <div className="flex items-center justify-between relative gap-2">
        <div className="flex items-center gap-4">
          {photo_url && (
            <img
              className="h-16 w-16 shrink-0 object-cover rounded-full"
              src={photo_url}
              alt=""
            />
          )}
          <div className="space-y-1">
            <div className="flex items-center gap-2">
              <img
                className="h-4 object-contain rounded-md"
                src={ICON_TYPES[type as keyof typeof ICON_TYPES]}
                alt=""
              />
              <h4 className="text-font text-base">
                {type.charAt(0).toUpperCase() + type.substring(1)}
              </h4>
            </div>
            <h3 className="text-font font-medium transition-colors text-xl break-all">
              {realname || username}
            </h3>
          </div>
        </div>
        <div className="flex gap-3">
          <Button
            disabled={isLoading}
            type="button"
            variant="outline"
            onClick={handleDelete}
            className="h-9 w-9"
            size="icon"
          >
            <Trash2 size={16} />
          </Button>
          <Button asChild className="h-9">
            <Link
              //   to={`/${props.id}/search`}
              to={`/people-investigated/${props.id}`}
              className="flex items-center gap-2"
            >
              <Search size={16} />
              Investigate
            </Link>
          </Button>
        </div>
      </div>
      <AuthorRef person={props} hideInvestigate hideLeftBar />
    </Card>
  );
}
