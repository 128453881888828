import * as React from "react";

import { cn } from "@/utils/cn";

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  startContent?: React.ReactNode;
  labelClassName?: string;
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, type, id, startContent, labelClassName, ...props }, ref) => {
    return (
      <label
        htmlFor="id"
        className={cn("relative flex items-center gap-2", labelClassName)}
      >
        {startContent && (
          <div className="absolute left-3.5 text-slate-500 dark:text-slate-400">
            {startContent}
          </div>
        )}
        <input
          type={type}
          className={cn(
            "flex h-10 w-full text-foreground dark:text-white rounded-md border px-3 py-2 text-base file:border-0 file:bg-transparent file:text-sm file:font-medium focus-visible:outline-none disabled:cursor-not-allowed disabled:opacity-50 placeholder:text-slate-500 dark:placeholder:text-slate-400 border-slate-200 bg-white dark:border-[#1F242F] dark:bg-[#0C111D]",
            startContent ? "pl-9" : "pl-3",
            className
          )}
          id={id}
          ref={ref}
          {...props}
        />
      </label>
    );
  }
);
Input.displayName = "Input";

export { Input };
