import Toast from "@/components/Toast";
import { getTaskInfo } from "@/utils/single-item/lib";
import axios from "axios";
import { useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";

export default function useNearby(onSuccess?: () => void) {
  const { type, item_id, workspace_id } = useParams();
  const [analysis, setAnalysis] = useState("");
  const [areLoading, setAreLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [results, setResults] = useState<Marker[]>([]);
  const timer = useRef<number | null>(null);

  async function onSearch() {
    setAreLoading(true);
    const loading = toast.custom((t) => (
      <Toast
        isLoading
        {...t}
        title="Searching for activity nearby"
        subtitle="Activity will be added to the map"
      />
    ));
    try {
      const { data } = await axios.get(
        "/searchNearby/" + workspace_id + "/" + type + "/" + item_id
      );
      setIsDisabled(true);
      timer.current = window.setInterval(async () => {
        const taskId = data.task_id;
        const {
          results: [{ state, result }],
        } = await getTaskInfo(taskId);
        if (state === "SUCCESS") {
          result.analysis && setAnalysis(result.analysis);
          const results = (result.results || []) as Marker[];
          if (results.length > 0) {
            setResults(results);
            onSuccess && onSuccess();
          } else {
            toast.custom((t) => (
              <Toast {...t} isError title={"No results have been found!"} />
            ));
          }
        }
        if (state !== "PENDING" && state !== "PROGRESS") {
          timer.current && window.clearInterval(timer.current);
          toast.dismiss(loading);
          setAreLoading(false);
        }
      }, 1000);
    } catch (error) {
      console.error("There was an error!", error);
      setAreLoading(false);
      toast.custom((t) => (
        <Toast
          {...t}
          isError
          title="Error"
          subtitle={(error as Error).message}
        />
      ));
    }
  }

  useEffect(() => {
    setResults([]);
    setIsDisabled(false);
  }, [item_id, workspace_id, type]);

  return {
    analysis,
    isDisabled,
    results,
    areLoading,
    onSearch,
  };
}
