import { Fragment, useRef, useState } from "react";
import toast from "react-hot-toast";
import Toast from "../Toast";
import { useAuth0 } from "@auth0/auth0-react";
import { getTaskInfo, getAuthorSearchId } from "../../utils/single-item/lib";
import { useParams } from "react-router-dom";
import AuthorRef from "./author-ref";
import { Card, CardTitle } from "../ui/card";
import { Button } from "../ui/button";
import { ScrollArea } from "../ui/scroll-area";
import { cn } from "@/utils/cn";
import useItem from "@/lib/single-item";
// import { waveform } from "ldrs";

// waveform.register();

export default function AuthorInfo({ item }) {
  const people = item.people || [];
  const person = Array.isArray(item.person)
    ? item.person[0]
    : item.person || null;
  const { type, item_id, workspace_id } = useParams();
  const { getAccessTokenSilently } = useAuth0();
  const [isLoading, setIsLoading] = useState(false);
  const timer = useRef(null);
  const [isExpanded, setIsExpanded] = useState({ person: true, people: true });
  const hasAnyData = people.length > 0 || !!person;
  const isSinglePerson = person && people.length === 0;
  const isMultiplePeople = people.length > 0 && !person;
  const isSingleAndMultiple = people.length > 0 && person;
  const { mutate } = useItem(item_id, type, workspace_id);

  async function searchForAuthors() {
    setIsLoading(true);
    const loadingId = toast.custom(
      (t) => (
        <Toast
          {...t}
          isLoading
          title="Loading author data..."
          subtitle="Fetching information about the author(s) of this object"
        />
      ),
      { duration: Infinity }
    );
    try {
      const token = await getAccessTokenSilently();
      const taskId = await getAuthorSearchId(token, type, item_id);
      if (!taskId.includes("-")) {
        // check if taskId is a uuid, if not it's an error regarding no credits
        toast.dismiss(loadingId);
        toast.custom((t) => (
          <Toast {...t} isError title="Error" subtitle="No credits" />
        ));
        return;
      }
      timer.current = setInterval(async () => {
        const {
          results: [{ state }],
        } = await getTaskInfo(taskId);
        if (state === "SUCCESS") {
          mutate();
        }
        if (state !== "PENDING") {
          clearInterval(timer.current);
          setIsLoading(false);
          toast.dismiss(loadingId);
        }
      }, 1000);
    } catch (err) {
      console.log(err);
      toast.dismiss(loadingId);
      toast.custom((t) => (
        <Toast {...t} isError title="Error" subtitle={err.message} />
      ));
    }
  }

  return (
    <Card className={cn("col-[2/3] row-[1/2]", hasAnyData && "self-start")}>
      <CardTitle>
        {hasAnyData
          ? isSinglePerson
            ? "Author info"
            : isSingleAndMultiple || person?.is_superhost
            ? "Person"
            : "Author info"
          : "Author info"}
      </CardTitle>
      {hasAnyData ? (
        <Fragment>
          {(isSinglePerson || isSingleAndMultiple) && (
            <div
              className={`transition-[grid-template-rows_500ms] grid ${
                isExpanded.person ? "grid-rows-[1fr]" : "grid-rows-[0fr]"
              }`}
            >
              <div className="overflow-hidden">
                <AuthorRef person={person} isFirst />
              </div>
            </div>
          )}
          {(isMultiplePeople || isSingleAndMultiple) && (
            <Fragment>
              {isSingleAndMultiple && (
                <button
                  onClick={() =>
                    setIsExpanded((prev) => ({ ...prev, people: !prev.people }))
                  }
                  className="flex items-center justify-between mt-4"
                >
                  <CardTitle>Guest List</CardTitle>
                  <div className="flex items-center gap-3 stroke-[var(--text-color)]">
                    <span className="font-medium text-2xl text-[var(--text-color)]">
                      {people.length}
                    </span>
                    <div
                      className={`w-max transition-transform opacity-80 ${
                        isExpanded.people ? "rotate-180" : "rotate-90"
                      }`}
                    >
                      <svg
                        width="14"
                        height="9"
                        viewBox="0 0 14 9"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M1 8L7 2L13 8"
                          stroke="inherit"
                          strokeWidth="2"
                        />
                      </svg>
                    </div>
                  </div>
                </button>
              )}
              <div
                className={`transition-[grid-template-rows_500ms] grid ${
                  isExpanded.people ? "grid-rows-[1fr]" : "grid-rows-[0fr]"
                }`}
              >
                <div className="overflow-hidden">
                  <ScrollArea className="h-96">
                    <div className="flex flex-col">
                      {people.map((prsn, i) => (
                        <AuthorRef person={prsn} key={`list:${i}`} />
                      ))}
                    </div>
                  </ScrollArea>
                </div>
              </div>
            </Fragment>
          )}
        </Fragment>
      ) : (
        <div className="flex flex-col items-center gap-4 flex-1 justify-center border-slate-300 dark:border-slate-800 border rounded-md min-h-48">
          {isLoading ? (
            <l-waveform size={48} color="var(--text-color)"></l-waveform>
          ) : (
            <>
              <Button
                variant="outline"
                disabled={isLoading}
                onClick={searchForAuthors}
              >
                <svg
                  width="20"
                  height="18"
                  viewBox="0 0 20 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M3.33268 12.5352C2.32769 11.8625 1.66602 10.7168 1.66602 9.41667C1.66602 7.46369 3.15894 5.85941 5.0658 5.68281C5.45586 3.31011 7.51622 1.5 9.99935 1.5C12.4825 1.5 14.5428 3.31011 14.9329 5.68281C16.8398 5.85941 18.3327 7.46369 18.3327 9.41667C18.3327 10.7168 17.671 11.8625 16.666 12.5352M6.66602 13.1667L9.99935 16.5M9.99935 16.5L13.3327 13.1667M9.99935 16.5V9"
                    stroke="currentColor"
                    strokeWidth="1.66667"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                Check owner account
              </Button>
              <p className="text-base text-font opacity-80">
                Gather data on the account
              </p>
            </>
          )}
        </div>
      )}
    </Card>
  );
}
