import axios from "axios";
import useSWR, { SWRConfiguration } from "swr";

async function getItem(id: number, module_name: string, workspace_id: number) {
  const { data } = await axios.get(
    `/getCoordinatesItem/${workspace_id}/${module_name}/${id}`
  );
  return data;
}

export default function useItem(
  id: string,
  moduleName: string,
  workspaceId: string,
  config?: SWRConfiguration<SingleRecord>
) {
  return useSWR(
    [id, workspaceId].some((k) => isNaN(parseInt(k)))
      ? null
      : ["single-item", parseInt(id), moduleName, parseInt(workspaceId)],
    ([_, item_id, module_name, workspace_id]) =>
      getItem(item_id, module_name, workspace_id),
    config
  );
}
