import { ScrollArea } from "@/components/ui/scroll-area";
import useDailyDashboard from "@/lib/daily-dashboard";
import { Newspaper } from "lucide-react";
import { Link, useParams } from "react-router-dom";

export default function NewsList({ date }: DailyDashboardParams) {
  const { workspace_id } = useParams() as Readonly<{ workspace_id: string }>;
  const { data } = useDailyDashboard(workspace_id, date);
  return (
    <div className="flex flex-col pt-4">
      <h3 className="text-xl text-font opacity-75 mb-1 px-6">News</h3>
      <p className="mb-4 px-6">
        <strong className="text-4xl font-medium text-font">
          {data?.statistics?.news || "-"}
        </strong>
      </p>
      {data?.objects?.news && data.objects.news.length > 0 ? (
        <div className="flex-1 relative">
          <div className="absolute inset-0 w-full h-full">
            <ScrollArea className="h-full px-6">
              {data.objects.news.map((news) => (
                <NewsRef {...(news as News)} key={news.id} />
              ))}
            </ScrollArea>
          </div>
        </div>
      ) : (
        <div className="flex-1 py-4 justify-center items-center flex flex-col gap-2 text-font opacity-80">
          <Newspaper size={18} />
          <p className="text-center text-base">No news found</p>
        </div>
      )}
    </div>
  );
}

const NewsRef = ({
  url,
  photo_url,
  timestamp,
  category,
  summary,
  author,
  title,
}: News) => (
  <Link to={url} target="_blank" rel="noreferrer" className="group/news">
    <div className="gap-y-1 gap-x-4 grid grid-cols-[1fr_3fr] border-b group-last/news:border-b-0 dark:border-[#1F242F]">
      <div className="row-span-4 relative">
        <img
          className="w-full h-full object-cover absolute inset-0"
          src={photo_url}
          alt=""
        />
      </div>
      <div className="text-[var(--primary-color2)] flex items-center justify-between gap-4 text-sm opacity-80 pt-2">
        <span>{new Date(timestamp).toLocaleDateString()}</span>
        {category && <span>{category.replace("_", ", ")}</span>}
      </div>
      <p className="text-[var(--text-color)] text-base font-semibold">
        {title}
      </p>
      <p className="text-[var(--text-color)] opacity-80 text-sm leading-normal line-clamp-3">
        {summary}
      </p>
      <p className="pb-2 text-[var(--text-color)] font-medium text-sm leading-normal mt-1">
        {author}
      </p>
    </div>
  </Link>
);
